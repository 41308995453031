import React from "react";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
import avtar from "../../assets/images/avtar.jpg"; 
export default function  AssignTask() {
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fafa-users me-2" />
                Job Schedule
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Job Schedule
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards user-list">
              <div className="row">
                <div className="col-lg-12">
                  <div className="bus-filter">
                    <div className="row justify-content-end">
                      <form action="">
                        <div className="row">
                          <div className="col-lg-2">
                            <a
                              href="javascript:void(0);"
                              className="thm-btn brdr-btn fltr-btn w-100 text-center"
                            >
                              <i className="fa fa-filter me-2" />
                              Filter
                            </a>
                          </div>
                          <div className="col-lg-2">
                            <div className="dropdown">
                              <button
                                className="export-btn btn dropdown-toggle"
                                type="button"
                                id="dropdown"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                View options 
                              </button>
                              <ul
                                className="dropdown-menu export-menu"
                                aria-labelledby="dropdown"
                              >
                                <li>
                                  <input type="file" id="csv" />
                                  <label
                                    htmlFor="csv"
                                    className="dropdown-item"
                                  >
                                    As CSV
                                  </label>
                                </li>
                                <li>
                                  <input type="file" id="xls" />
                                  <label
                                    htmlFor="xls"
                                    className="dropdown-item"
                                  >
                                    As XLS
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <select name="" id="" className="form-select">
                              <option value="">Week</option>
                            </select>
                          </div>
                          <div className="col-lg-2">
                            <input
                              type="date"
                              className="form-control"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="table custom-table bus-management-table">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="cstm-w">
                            <div className="form-bx">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search User"
                              />
                              <span className="fa fa-search" />
                            </div>
                          </th>
                          <th>
                            Mon 4/8
                            <p className="usr-val">
                              <span>
                                <i className="fa fa-clock-o me-1" />
                                --
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                            </p>
                          </th>
                          <th>
                            Tue 4/9
                            <p className="usr-val">
                              <span>
                                <i className="fa fa-clock-o me-1" />
                                --
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                            </p>
                          </th>
                          <th>
                            Wed 4/10
                            <p className="usr-val">
                              <span>
                                <i className="fa fa-clock-o me-1" />
                                --
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                            </p>
                          </th>
                          <th>
                            Thu 4/11
                            <p className="usr-val">
                              <span>
                                <i className="fa fa-clock-o me-1" />
                                --
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                            </p>
                          </th>
                          <th>
                            Fri 4/12
                            <p className="usr-val">
                              <span>
                                <i className="fa fa-clock-o me-1" />
                                --
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                            </p>
                          </th>
                          <th>
                            Sat 4/13
                            <p className="usr-val">
                              <span>
                                <i className="fa fa-clock-o me-1" />
                                --
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                              <span>
                                <i className="fa fa-user me-1" />0
                              </span>
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="table-usr-prfl">
                              <div className="table-usr-prfl-img">
                                <img src={avtar} alt="" />
                              </div>
                              <div className="table-usr-prfl-name">
                                <h4>Ajay Sharma</h4>
                                <p className="usr-val">
                                  <span>
                                    <i className="fa fa-clock-o me-1" />
                                    16:00
                                  </span>
                                  <span>
                                    <i className="fa fa-user me-1" />0
                                  </span>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                          <td className="cstm-w">
                            <div className="ellips-menu">
                              <div className="assin-bx">
                                <h5>9AM to 5PM</h5>
                                <h6>
                                  Customer &gt; <span>Regular cleaning</span>
                                </h6>
                              </div>
                              <div className="tsk-done">
                                <p>
                                  <i className="fa fa-check me-1" />
                                  Task Completed
                                </p>
                              </div>
                              <div className="dropdown">
                                <button
                                  className="drop-btn"
                                  type="button"
                                  id="dropmenu"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropmenu"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Done Task
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="cstm-w">
                            <div className="ellips-menu">
                              <div className="assin-bx">
                                <h5>9AM to 5PM</h5>
                                <h6>
                                  Customer &gt; <span>Regular cleaning</span>
                                </h6>
                              </div>
                              <div className="tsk-done opacity-0">
                                <p>
                                  <i className="fa fa-check me-1" />
                                  Task Done
                                </p>
                              </div>
                              <div className="dropdown">
                                <button
                                  className="drop-btn"
                                  type="button"
                                  id="dropmenu"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropmenu"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Done Task
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table-usr-prfl">
                              <div className="table-usr-prfl-img">
                                <img src={avtar} alt="" />
                              </div>
                              <div className="table-usr-prfl-name">
                                <h4>Ajay Sharma</h4>
                                <p className="usr-val">
                                  <span>
                                    <i className="fa fa-clock-o me-1" />
                                    16:00
                                  </span>
                                  <span>
                                    <i className="fa fa-user me-1" />0
                                  </span>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                          <td className="cstm-w">
                            <div className="ellips-menu">
                              <div className="assin-bx">
                                <h5>9AM to 5PM</h5>
                                <h6>
                                  Customer &gt; <span>Regular cleaning</span>
                                </h6>
                              </div>
                              <div className="tsk-done">
                                <p>
                                  <i className="fa fa-check me-1" />
                                  Task Done
                                </p>
                              </div>
                              <div className="dropdown">
                                <button
                                  className="drop-btn"
                                  type="button"
                                  id="dropmenu"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropmenu"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Done Task
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="cstm-w">
                            <div className="ellips-menu">
                              <div className="assin-bx">
                                <h5>9AM to 5PM</h5>
                                <h6>
                                  Customer &gt; <span>Regular cleaning</span>
                                </h6>
                              </div>
                              <div className="tsk-done opacity-0">
                                <p>
                                  <i className="fa fa-check me-1" />
                                  Task Done
                                </p>
                              </div>
                              <div className="dropdown">
                                <button
                                  className="drop-btn"
                                  type="button"
                                  id="dropmenu"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fa fa-ellipsis-v" />
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropmenu"
                                >
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Delete
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Done Task
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                          <td className="cstm-menu-hvr">
                            <div className="add-btn">
                              <a
                                href="javascript:void(0);"
                                data-bs-toggle="modal"
                                data-bs-target="#add-shift"
                              >
                                <i className="fa fa-plus" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade shift-model right"
        id="add-shift"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Tuesday, May 26, 2024
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="shift-model-form">
                <form action="">
                  <div className="row mb-3">
                    <div className="col-lg-6 d-flex align-items-baseline">
                      <label htmlFor="" className="form-label me-2">
                        Date
                      </label>
                      <input type="date" className="form-control" />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="checkbox"
                        className="btn-check"
                        id="all-day"
                        autoComplete="off"
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="all-day"
                      >
                        All Day
                      </label>
                      <br />
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-4">
                      <label htmlFor="" className="form-label">
                        Start Time
                      </label>
                      <input type="time" className="form-control" />
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="" className="form-label">
                        End Time
                      </label>
                      <input type="time" className="form-control" />
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="" className="form-label cstm-lb">
                        Total Hours
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-5 mb-3">
                      <label htmlFor="" className="form-label">
                        Shift title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type Here"
                      />
                    </div>
                    <div className="col-lg-5 mb-3">
                      <label htmlFor="" className="form-label">
                        Job
                      </label>
                      <select name="" id="" className="form-select">
                        <option selected="">Select Job</option>
                        <option value="">Shift Manager</option>
                        <option value="">Customer</option>
                      </select>
                    </div>
                    <div className="col-lg-2">
                      <label htmlFor="" className="form-label">
                        Job Color
                      </label>
                      <input type="color" className="form-control" />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="form-label">
                        Assign User
                      </label>
                      <select name="" id="" className="form-select">
                        <option selected="">Select User</option>
                        <option value="">user 1</option>
                        <option value="">user 2</option>
                      </select>
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label htmlFor="" className="form-label">
                        Location
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Type Location"
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <label htmlFor="" className="form-label">
                        Note
                      </label>
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        defaultValue={""}
                      />
                    </div>
                    <div className="col-lg-12 upload-box mb-3">
                      <span className="d-block mb-2">Upload Barcode</span>
                      <input type="file" id="file" />
                      <label htmlFor="file">
                        <i className="fafa-upload" />
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button className=" thm-btn">Publish</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
