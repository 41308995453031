import React, { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../config/baseUrl';
import busImg from "../../assets/images/bus-img.png";
import { useNavigate, Link } from "react-router-dom";

import { toast, Bounce } from "react-toastify";

export default function ChangeScheduling() {
    const { id } = useParams()
    let Navigate=useNavigate()
    let [companyNameData, setCompanyNameData] = useState([]);
    let [formval, setFormval] = useState({ days: [] ,company:"",depo_address:"",depo_name:""});
    let dayData = ["monday","tuesday","wednesday","thursday", "friday","saturday","sunday"]
    let ChangeHandler = (e) => {
        if (e.target.name === "days") {
            return setFormval((curval) => {
                let day = (e.target.value); 
                if (curval.days.includes(day)) {
                    let data = curval.days.filter((item) => item !== day);
                    return { ...curval, [e.target.name]: data };
                }
                return {
                    ...curval,
                    [e.target.name]: [...curval.days, (e.target.value)],
                };
            });
        }
        setFormval((preVal) => ({ ...preVal, [e.target.name]: e.target.value }));
    };

    let loginUser = JSON.parse(localStorage.getItem("user"));

    let allData = async () => {
        let response = await axios(`${baseUrl}/module/admin/depo-viewset/${id}`, {
            method: "GET",
            headers: {
                Authorization: "Token " + localStorage.getItem("token"),
            },
        });
        setFormval({
            ...response?.data?.data,
            company: response?.data?.data?.company?.id,
        });
        // console.log(response.data.data)

    }
    const companyName = async () => {
        let response = await axios(`${baseUrl}/module/admin/company-viewset`, {
            method: "GET",
            headers: {
                Authorization: "Token " + localStorage.getItem("token"),
            },
        });
        let allData = response.data?.data;
        allData = allData?.filter((arr) => arr.id === loginUser?.company);
        // console.log(allData)
        setCompanyNameData(allData);

    };
    useEffect(() => {
        allData();
        companyName();
    }, [])


    let handleSubmit = async (e) => {
        e.preventDefault();
        let id=formval.id;
        console.log(formval)
        
        // return 
        try {
          const response = await axios(`${baseUrl}/module/admin/depo_update/${id}`, {
          // const response = await axios(`${baseUrl}/module/admin/depo-viewset/`, {
            method: "POST",
            headers: {
              Authorization: "Token " + localStorage.getItem("token"),
            },
            data: {"days":formval.days},
          });
        //   toast.success("Edit depot Successfully", {
        //     position: "top-right",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     transition: Bounce,
        //   });
        console.log(response.data)
        Navigate("/depo-list-for-change-scheduling")
        } catch (error) {
          console.error("Error :", error);
        }
      };
    return (
        <section className="main-sec">
            <div className="row">
                <div className="col-lg-12">
                    <div className="dashboard-title">
                        <h4 className="dash-head">
                            <i className="fa fa-users me-2" />
                            View Users
                        </h4>
                    </div>
                    <div className="custom-bredcump">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="index.html">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    View Users
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="cards edit-usr">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="" className="form-label">
                                        Depot Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="depo_name"
                                        onChange={ChangeHandler}
                                        value={formval?.depo_name}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="" className="form-label">
                                        Depo Address
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="depo_address"
                                        onChange={ChangeHandler}
                                        value={formval?.depo_address}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-6 mb-4">
                                    <label htmlFor="" className="form-label">
                                        Company Name
                                    </label>
                                    <select name="user_type" disabled onChange={ChangeHandler}  value={formval?.company} className="form-select">
                                        {companyNameData?.map((arr) => (<option key={arr.id} value={arr.id}>{arr.company_name}</option>))}
                                    </select>
                                </div>
                                <div className="tablemenu-bar col-lg-4 mb-4 ms-4">
                                    
                                    <div className="dropdown">
                                        <button
                                            className="dropdown-toggle"
                                            type="button"
                                            id="tablemenu"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{color:"black",paddingTop:"7px", fontSize:"17px"}}
                                        >
                                            select Days{" "}
                                        </button>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="tablemenu"
                                        >
                                            {dayData?.map((arr) => (
                                                <li key={arr} className="dropdown-item">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input checkbox"
                                                            type="checkbox"
                                                            id="check-04"
                                                            name="days"
                                                            checked={
                                                                formval?.days?.includes(arr) && true
                                                            }
                                                            // onChange={addInputBox}
                                                            value={arr}
                                                            onChange={ChangeHandler}
                                                        />
                                                        <label htmlFor="check-04">
                                                            {arr}
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-12 text-center mt-4 mb-4">
                                    <button
                                        type="Submit"
                                        className="thm-btn"
                                    >
                                        Submit
                                         
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
