import React, { useState, useEffect } from "react";
import busImg from "../../assets/images/bus-img.png";
import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { toast, Bounce } from "react-toastify";
export default function BusAdd({ setCallApi }) {


  let [formval, setFormval] = useState({
    company: "",
    bus_number: "",
    depo: "",
    // depo_address: "",
  });
  let [depoData, setDepoData] = useState([]);
  let [companyNameData, setCompanyNameData] = useState([]);
  let loginUser = JSON.parse(localStorage.getItem("user"));

  let ChangeHandler = (e) => {
    setFormval((currVal) => {
      return { ...currVal, [e.target.name]: e.target.value };
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formval)
    if (
      !formval.company ||
      !formval.bus_number ||
      !formval.depo 
    ) {
      return alert("all fileds is required");
    }
    try {
      const response = await axios(`${baseUrl}/module/admin/bus-viewset`, {
        method: "POST",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        data: formval,
      });
      let unique = document.getElementById("unique");
      unique.click();
      setCallApi(Math.random());
      setFormval({
        company: "",
        bus_number: "",
        depo: "",
      });
      // console.log(response)
      
      if(response.data.code === 200){
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }else if(response.data.code === 403){
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }else{
        toast.error(`${response?.data?.error_message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
 
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const depo = async () => {
    let response = await axios(`${baseUrl}/module/admin/depo-viewset`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    setDepoData(response?.data?.data);
  };

  const companyName = async () => {
    let response = await axios(`${baseUrl}/module/admin/company-viewset`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    // let data = response.data?.data.filter((arr)=>())
    // setCompanyNameData()
    let allData = response?.data?.data;
    // setCompanyNameData(response.data?.data);
    allData = allData?.filter((arr) => arr.id === loginUser?.company);
    setCompanyNameData(allData);
    // setCompanyNameData(response.data?.data);
  };

  useEffect(() => {
    depo();
    companyName();
  }, []);

  return (
    <>
      <div
        className="modal fade custom-modal addbus-modal"
        id="addbus"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="">
                Add Bus
              </h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="unique"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src={busImg} alt="" />
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <select
                            name="company"
                            onChange={ChangeHandler}
                            className="form-select"
                            value={formval.company}
                          >
                            <option defaultValue="">Select Company Name</option>
                            {companyNameData.map((arr) => {
                              return (
                                <option key={arr?.id} value={arr?.id}>
                                  {arr.company_name}
                                </option>
                              );
                            })}
                          </select>
                          <span className="fa fa-building icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Bus Number"
                            name="bus_number"
                            onChange={ChangeHandler}
                            value={formval.bus_number}
                          />

                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <select
                            name="depo"
                            onChange={ChangeHandler}
                            className="form-select"
                            value={formval.depo}
                          >
                            <option defaultValue="">Select Depo Name</option>
                            {depoData.map((arr) => {
                              return (
                                <option key={arr?.id} value={arr?.id}>
                                  {arr.depo_name}
                                </option>
                              );
                            })}
                          </select>
                          <span className="fa fa-server icon" />
                        </div>
                        {/* <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Depo Address"
                            name="depo_address"
                            onChange={ChangeHandler}
                          />
                          <span className="fa fa-server icon" />
                        </div> */}
                        {/* <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <select
                            name="cleaning_type"
                            onChange={ChangeHandler}
                            className="form-select"
                            value={formval.cleaning_type}
                          >
                            <option defaultValue="">Enter Cleaning Type</option>
                            <option value="REGULAR_CLEANING">
                              Regular Cleaning
                            </option>
                            ;
                            <option value="STANDARD_DEEP_CLEANING">
                              Standard Deep Cleaning
                            </option>
                            ;
                            <option value="ANNUAL_DEEP_CLEANING">
                              Annual Deep Cleaning
                            </option>
                          </select>
                          <span className="fa fa-bus icon" />
                        </div> */}
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="submit">
                           Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
