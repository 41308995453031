import React, { useState, useEffect } from "react";
import Loginimg from "../../assets/images/login-img.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { toast, Bounce } from "react-toastify";
import { ToastMessgae } from "../utils/toast";
import { baseUrl } from "../../config/baseUrl";
import logo from "../../assets/images/head-logo.png";
export default function Login() {
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  const [showPass, setshowPass] = useState(false);

  useEffect(() => {
    localStorage.clear();
    if (token != null) {
      navigate("/");
    }
  });

  let [formval, setFormval] = useState({ username: "", password: "" });
  let ChangeHandler = (e) => {
    setFormval((currVal) => {
      return { ...currVal, [e.target.name]: e.target.value };
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formval);
    if (!formval.username || !formval.password) {
      return alert("all fileds is required");
    }
    try {
      const response = await axios(`${baseUrl}/module/admin/login`, {
        method: "POST",
        data: formval,
      });
      // console.log(response);
      if (response?.data?.data?.token) {
        localStorage.setItem("token", response?.data?.data?.token);
        localStorage.setItem(
          "user",
          JSON.stringify(response?.data?.data?.user_data)
        );
        // console.log(response);
        navigate("/");
        ToastMessgae(response?.data);
      } else {
        ToastMessgae(response?.data);
      }
    } catch (error) {
      console.log("Error :", error);
      toast.error(`Something Went Wrong..!, Please Contact Developer Team`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      // alert("Something Went Wrong..! , Please Contact Developer Team")
    }
  };

  return (
    <>
      <section className="main-login">
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-xl-6 b-center bg-size d-none d-lg-block d-xl-block"
              style={{
                backgroundImage: `url(${Loginimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                display: "block",
              }}
            >
              <div className="login-lft">
                <div className="login-lft-innr">
                  <h3>Welcome</h3>
                  <span>
                    {" "}
                    <img className="img-logo" src={logo} />{" "}
                  </span>
                  <p className="text-white">
                    AFC Tracker is an Application designed by Advanced Facility
                    Care (New Zealand) Limited. This app is specifically made to
                    easily track cleaning, such as times and frequency of
                    cleans. The app will generate reports daily with manager
                    access and client portal for a more transparent and
                    proactive approach. All rights reserved by Advanced Facility
                    Care New Zealand Limited.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 p-0">
              <div className="login-card">
                <form action="" onSubmit={handleSubmit} className="login-form">
                  <h4>
                    <span>Login</span>
                  </h4>
                  <div className="mb-4 frm-bx">
                    <label htmlFor="" className="form-label">
                      User Name/Mobile Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      onChange={ChangeHandler}
                      placeholder="UserName"
                      value={formval.username}
                    />
                    <span className="fa fa-user" />
                  </div>
                  <div className="mb-3 frm-bx">
                    <label htmlFor="" className="form-label">
                      Password
                    </label>
                    <input
                      type={showPass ? "text" : "password"}
                      className="form-control"
                      name="password"
                      onChange={ChangeHandler}
                      placeholder="Password"
                      value={formval.password}
                    />
                    {showPass ? (
                      <span
                        className="fa fa-eye-slash"
                        onClick={() => setshowPass(!showPass)}
                      />
                    ) : (
                      <span
                        className="fa fa-eye"
                        onClick={() => setshowPass(!showPass)}
                      />
                    )}
                  </div>
                  <div className=" text-end">
                    <Link to="/forget-password">
                      <b> Forgot Password </b>
                    </Link>
                  </div>
                  <div className="mb-4 mt-5 frm-bx">
                    <button className="frm-btn w-100" type="submit">
                      Login
                    </button>
                  </div>
                  {/* <div className="mb-4 mt-5 frm-bx">
                    <button className=" w-100  btn btn-warning" type="submit">
                      Forget Password
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
