import React, { useState, useEffect } from "react";
import busImg from "../../assets/images/bus-img.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
import { toast, Bounce } from "react-toastify";
export default function CompanyAdd({ apicall, setApicall }) {
  let navigate = useNavigate();

  let [formval, setFormval] = useState({ company_name: "" });
  let ChangeHandler = (e) => {
    setFormval((preVal) => ({ ...preVal, [e.target.name]: e.target.value }));
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (!formval) {
      return alert("all fileds is required");
    }

    try {
      const response = await axios(`${baseUrl}/module/admin/company-viewset`, {
        method: "POST",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        data: formval,
      });
      // let apicall = apicall++;
     
      setApicall(Math.random());
      setFormval({
        company: "",
      });
      let unique = document.getElementById("unique");
      unique.click();
      navigate("/company-list");
      toast.success("Add Company Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      navigate("/company-list");
    } catch (error) {
      console.error("Error :", error);
    }
  };

  return (
    <>
      <div
        className="modal fade custom-modal addbus-modal"
        id="addbus"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="">
                Add Company
              </h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="unique"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src={busImg} alt="" />
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Company Name"
                            name="company_name"
                            onChange={ChangeHandler}
                            value={formval?.company_name}
                          />
                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="submit">
                          Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
