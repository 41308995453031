import React, { useEffect } from "react";
import avtar from "../../assets/images/avtar.jpg";
import { Link, useNavigate } from "react-router-dom";
// import DeleteToast from "../Users/DeleteToast";
import LogoutToast from "./LogoutToast";
import PasswordReset from "./PasswordReset";
export default function Header() {
  let navigate = useNavigate();
  // let [deleteid, setDeleteid] = useState();
  // let [logout, setlogout] = useState(false);
  let token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);
  let UserDetails = JSON.parse(localStorage.getItem("user"));

  // console.log(UserDetails)
  // manish@objectsquare.com
  // let logoutfunction = () => {
  //   localStorage.clear();
  //   navigate("/Login");
  // };
  const removeClass = (e) => {
    document?.body?.classList?.toggle("resize-menu");
  };
  const handleError = (e) => {
    e.target.src = avtar;
  };
  return (
    <>
      <header className="header">
        <div className="navbar-header">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6 col-sm-6 col-6">
              <div className="header-fltr">
                <Link to="" className="toggle" onClick={removeClass}>
                  <i className="fa fa-times" />
                </Link>
                <form action="">
                  <div className="header-fltr-bx">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Type here..."
                    />
                    <span className="fa fa-search" />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-6">
              <div className="app-header-right-side">
                {/* <ul className="app-header-right-side-list">
                  <li>
                    <Link to="">Help</Link>
                  </li>
                  <li>
                    <button type="button" className="position-relative">
                      <i className="fa fa-bell" />
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        1
                      </span>
                    </button>
                  </li>
                </ul> */}
                <div className="prfl-bar">
                  <div className="prfl-bar-img">
                    <img
                      onError={handleError}
                      src={`${UserDetails?.profile_image}`}
                      className="profle"
                      alt="profile_image"
                    />
                  </div>
                  <div className="prfl-bar-content">
                    <div className="dropdown">
                      <button
                        className="dropdown-toggle"
                        type="button"
                        id="profile-menu"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>
                          {UserDetails?.username} <br />
                          {UserDetails?.user_type}
                        </span>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="profile-menu"
                      >
                        {/* <li>
                          <Link to="">
                            <i className="fa fa-user-circle me-2" />
                            Switch to user view
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to="">
                            <i className="fa fa-cog me-2" />
                            Setting
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to="">
                            <i className="fa fa-bell me-2" />
                            Notification
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link>
                            <i className="fa fa-gift me-2" />
                            Perk Marketplace
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to=""
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i className="fa fa-lock me-2" />
                            Reset Password
                          </Link>
                        </li>
                        <li>
                          <Link
                            // onClick={() => setlogout(true)}
                            // to="/login"
                            // className="btn btn-outline-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#logout"
                          >
                            <i className="fa fa-sign-out me-2" />
                            Signout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <LogoutToast />
      <PasswordReset/>
    </>
  );
}
