import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
import { toast, Bounce } from "react-toastify";
export default function JobScheduleEdit() {
  let navigate = useNavigate();
  let { id } = useParams();
  let [companyNameData, setCompanyNameData] = useState([]);
  const [formval, setFormval] = useState({
    assign_date: "",
    bus: "",
    company: "",
    cleaning_type: "",
  });
  let [busData, setBusData] = useState([]);

  let changeHandler = (e) => {
    setFormval((preVal) => ({ ...preVal, [e.target.name]: e.target.value }));
  };

  let loginUser = JSON.parse(localStorage.getItem("user"));
  const companyName = async () => {
    let response = await axios(`${baseUrl}/module/admin/company-list`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    let allData = response.data?.data;
    // console.log(allData)
    allData = allData?.filter((arr) => arr.id === loginUser?.company);
    setCompanyNameData(allData);
  };
  const jobData = async () => {
    let response = await axios(
      `${baseUrl}/module/admin/adminscheduler-viewset/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      }
    );
    let data = response.data?.data;
    // console.log(data);
    data.company=data.bus.company
    data.bus=data.bus.id
    // allData = allData?.filter((arr) => arr.id === loginUser?.company);
    // setCompanyNameData(allData);
    setFormval(data);
  };

  useEffect(() => {
    jobData();
    try {
      (async () => {
        let response = await axios(`${baseUrl}/module/admin/bus-list`, {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        });
        // console.log(response.data.data);
        setBusData(response?.data?.data);
      })();
    } catch (error) {
      console.error("Error :", error.response.data);
    }
    companyName();
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formval);
    // return
    if (
      !formval.assign_date ||
      !formval.bus ||
      !formval.company ||
      !formval.cleaning_type
    ) {
      return alert("all fileds is required");
    }
    try {
      const response = await axios(
        `${baseUrl}/module/admin/adminscheduler-viewset/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
          data: formval,
        }
      );
      console.log(response.data);
      console.log(response);

      if (response.data.code === 201) {
        toast.success(response.data.message || "Updated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        navigate("/job-scheduled");
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      // navigate("/BusList")
    } catch (error) {
      console.error("Error :", error);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-users me-2" />
                Edit JobSchedule
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="index.html">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Edit Job Schedule
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards edit-usr">
              <form action="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Assign Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="assign_date"
                      onChange={changeHandler}
                      defaultValue={formval?.assign_date}
                    />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Bus Name
                    </label>
                    <select
                      onChange={changeHandler}
                      name="bus"
                      className="form-select"
                      value={formval?.bus}
                    >
                      <option value="">Select Bus </option>
                      {busData?.map((arr) => {
                        return (
                          <option key={arr?.id} value={arr?.id}>
                            {arr?.bus_number}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      Cleaning Type
                    </label>
                    <select
                      onChange={changeHandler}
                      name="cleaning_type"
                      className="form-select"
                      value={formval?.cleaning_type}
                    >
                      <option>Select Cleaning Type</option>
                      <option value={"REGULAR_CLEANING"}>
                        REGULAR_CLEANING
                      </option>
                      <option value={"ANNUAL_DEEP_CLEANING"}>
                        ANNUAL_DEEP_CLEANING
                      </option>
                      <option value={"STANDARD_DEEP_CLEANING"}>
                        STANDARD_DEEP_CLEANING
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label htmlFor="" className="form-label">
                      company
                    </label>
                    <select
                      name="company"
                      onChange={changeHandler}
                      value={formval?.company}
                      className="form-select"
                    >
                      <option value="">Select company name</option>
                      <option value={companyNameData[0]?.id}>
                        {companyNameData[0]?.company_name}
                      </option>
                    </select>
                  </div>

                  <div className="col-lg-12 text-center">
                    <button type="Submit" className="thm-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
