import React, { useEffect, useState } from "react";

import axios from "axios";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
import UserAdd from "./UserAdd";
import UserListTable from "./UserListTable";
export default function UserList() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  let [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  let [pageNumberAdmin, setPageNumberAdmin] = useState(1);
  let [pageLimitAdmin, setPageLimitAdmin] = useState(1);
  let [pageNumberArchive, setPageNumberArchive] = useState(1);
  let [pageLimitArchive, setPageLimitArchive] = useState(1);


  // const [pageLimit, setPageLimit] = useState(1);
  // let [pageLimitAdmin, setPageLimitAdmin] = useState(1);
  // let [pageNumberArchive, setPageNumberArchive] = useState(1);

  // const [paginationDetails, setPaginationDetails] = useState({});
  const [paginationDetails, setPaginationDetails] = useState({});
  const [paginationDetailsAdmin, setPaginationDetailsAdmin] = useState({});
  const [paginationDetailsArchive, setPaginationDetailsArchive] = useState({});
  // let [pageLimitAdmin, setPageLimitAdmin] = useState(1);
  // let [pageNumberArchive, setPageNumberArchive] = useState(1);
  

  const [userData, setUserData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [userCount, setUserCount] = useState([]);
  const [adminCount, setAdminCount] = useState([]);
  const [modelType, setModelType] = useState("ADMIN_MANAGER");

  const [archivecount, setArchiveCount] = useState();
  const [showModel, setShowModel] = useState(1);
  const [archivedVal, setArchivedVal] = useState("Archived");
  let [companyNameData, setCompanyNameData] = useState([]);
  let [searchData, setSearchData] = useState({ company: "", user_type: "" })
  let [modelPageCountType,setmodelPageCountType]=useState("user")
  // let [searchDataAdmin, setSearchDataAdmin] = useState({ company: "" })
  // let [searchDataArchive, setSearchDataArchive] = useState({ company: "", user_type: "" })


  //Admin Data api 
  const adminDataApi = async () => {
    let response = await axios(`${baseUrl}/module/admin/adminlist?page=${pageNumberAdmin}&company=${searchData.company}`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    if (response?.data.code === 401) {
      navigate("/login");
      return;
    }
    // console.log(response.data);
    // setAdminData(
    //   response?.data?.data.filter((arr) => arr?.user_type === "ADMIN_MANAGER")
    // );
    setAdminData(response?.data?.data || [])
    setPaginationDetailsAdmin(response?.data?.extra)
    setPageLimitAdmin(response?.data?.extra.total)
    setArchivedVal("Archived");
  };
  useEffect(() => {
    adminDataApi()
  }, [pageNumberAdmin])

  //user Data Api
  const usersDataApi = async () => {
    let response = await axios(`${baseUrl}/module/admin/user-viewset?page=${pageNumber}&company=${searchData.company}&user_type=${searchData?.user_type}`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    if (response?.data?.code === 401) {
      navigate("/login");
      return;
    }
    setPaginationDetails(response?.data?.extra)
    setUserData(response?.data?.data || []);
    setPageLimit(response?.data?.extra?.total);
    setArchivedVal("Archived");
  };

  let archiveUserData = async () => {
    setModelType("ADMIN_MANAGER");
    setArchivedVal("Restore");
    let response = await axios(`${baseUrl}/module/admin/archive-viewset?page=${pageNumberArchive}`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    countApi()
    setArchiveData(response?.data?.data||[]);
    setPageLimitArchive(response?.data?.extra?.total);
    setPaginationDetailsArchive(response?.data?.extra)
  };

  useEffect(() => {
    archiveUserData();
  }, [pageNumberArchive])

  //company data
  const companyName = async () => {
    let response = await axios(`${baseUrl}/module/admin/company-list`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    setCompanyNameData(response?.data?.data || []);
  };

  useEffect(() => {
    usersDataApi();
    countApi();
  }, [showModel, pageNumber]);

  useEffect(() => {
    countApi();
  }, [showModel])

  useEffect(() => {
    companyName();
  }, [])

  //Archived User Get data



  // countApi
  let countApi = async () => {
    let response = await axios(`${baseUrl}/module/admin/count-viewset`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    console.log(response.data)
    setUserCount(response?.data?.user);
    setAdminCount(response?.data?.admin);
    setArchiveCount(response?.data?.archive);
    // console.log(response)
  };

  useEffect(() => {
    let queryString = searchParams.get("model")
    // console.log(queryString)
    if (queryString === "admin") {
      let adminButton = document?.getElementById("adminButton");
      adminButton.click();
    }
    if (queryString === "archive") {
      let archiveButton = document?.getElementById("archiveButton");
      archiveButton.click();
    }
  }, [])


  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-users me-2" />
                {modelType === "SUPER_ADMIN" ? "Admin List" : (archivedVal === "Archived")? "Users List":"Archive List"}
              </h4>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {modelType === "SUPER_ADMIN" ? "Admin" : (archivedVal === "Archived")? "Users":"Archive Users"}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cstm-tab">
              <ul className="nav" id="myTab">
                <li className="nav-item">
                  <button
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-01"
                    type="button"
                    role="tab"

                    onClick={  () => {
                      setSearchData({ company: "", user_type: "" })
                      searchData = { company: "", user_type: "" }
                      setModelType("ADMIN_MANAGER");
                      setPageNumber(1)
                      usersDataApi();
                      setmodelPageCountType("user")
                    }}
                  >
                    Users &nbsp; ({userCount})
                    {/* {archivedVal === "Restore" ? userCount : userData.length}) */}
                    {/* ({userData.length || userCount}) */}
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-02"
                    type="button"
                    id="adminButton"
                    role="tab"
                    // onClick={() => setModelType("SUPER_ADMIN")}
                    onClick={() => {
                      setSearchData({ company: "", user_type: "" })
                      searchData = { company: "", user_type: "" }
                      setModelType("SUPER_ADMIN");
                      adminDataApi()
                      setmodelPageCountType("admin")
                    }}
                  >
                    Admin
                    {/* ({adminData.length}) */}(
                    {adminCount})
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab-03"
                    type="button"
                    role="tab"
                    id="archiveButton"
                    onClick={() => {
                      setSearchData({ company: "", user_type: "" })
                      searchData = { company: "", user_type: "" }
                      archiveUserData()
                      setmodelPageCountType("archive")
                    }}
                  >
                    ARCHIVED &nbsp; ({archivecount})
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <UserListTable
                  userData={userData}
                  setUserData={setUserData}
                  setArchiveData={setArchiveData}
                  archiveData={archiveData}
                  modelType={modelType}
                  setArchiveCount={setArchiveCount}
                  adminData={adminData}
                  setAdminData={setAdminData}
                  archivedVal={archivedVal}
                  pageLimit={pageLimit}
                  companyNameData={companyNameData}
                  countApi={countApi}
                  searchData={searchData}
                  setSearchData={setSearchData}
                  setPageNumber={setPageNumber}
                  
                  pageNumber={pageNumber}
                  setPageLimit={setPageLimit}
                  setPageNumberAdmin={setPageNumberAdmin}
    

                  pageNumberAdmin={pageNumberAdmin}
                  setPageLimitAdmin={setPageLimitAdmin}
                  pageLimitAdmin={pageLimitAdmin}
                  pageNumberArchive={pageNumberArchive}
                  setPageNumberArchive={setPageNumberArchive}
                  pageLimitArchive={pageLimitArchive}
                  setPageLimitArchive={setPageLimitArchive}

                  paginationDetailsAdmin={paginationDetailsAdmin}
                  setPaginationDetailsAdmin={setPaginationDetailsAdmin}
                  paginationDetails={paginationDetails}
                  setPaginationDetails={setPaginationDetails}
                  paginationDetailsArchive={paginationDetailsArchive}
                  setPaginationDetailsArchive={setPaginationDetailsArchive}
                  modelPageCountType={modelPageCountType}
                   
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add-user-modal-start  */}

      <UserAdd
        modelType={modelType}
        showModel={showModel}
        setShowModel={setShowModel}
        pageLimit={pageLimit}
        adminDataApi={adminDataApi}
        companyNameData={companyNameData}
      />

    </>
  );
}
