import React, { useState, useEffect } from "react";
import busImg from "../../assets/images/bus-img.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
import { toast, Bounce } from "react-toastify";
export default function DepoEdit({editVal, setEditval,setApicall,apicall}) {
  let navigate = useNavigate();

  let [companyNameData, setCompanyNameData] = useState([]);
  
  let ChangeHandler = (e) => {
    setEditval((preVal) => ({ ...preVal, [e.target.name]: e.target.value }));
  };

  let loginUser = JSON.parse(localStorage.getItem("user"));



  useEffect(() => {
    const companyName = async () => {
      let response = await axios(`${baseUrl}/module/admin/company-viewset`, {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      let allData = response.data?.data;
      allData = allData?.filter((arr) => arr.id === loginUser?.company);
      setCompanyNameData(allData);

    };
    companyName();
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    let id=editVal.id;
    try {
      const response = await axios(`${baseUrl}/module/admin/depo-viewset/${id}`, {
      // const response = await axios(`${baseUrl}/module/admin/depo-viewset/`, {
        method: "PUT",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        data: editVal,
      });
      // console.log(response?.data.data);
      // let a=apicall+1
      setApicall(Math.random());
      let unique = document.getElementById("Close");
      unique.click();
      toast.success("Edit depot Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Error :", error);
    }
  };

  return (
    <>
      <div
        className="modal fade custom-modal addbus-modal"
        id="editbus"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="">
                Edit Depot
              </h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="Close"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src={busImg} alt="" />
                    <form action=""  onSubmit={handleSubmit} >
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Depot Name"
                            name="depo_name"
                            onChange={ChangeHandler}
                            value={editVal.depo_name}
                          />
                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Depo Address"
                            name="depo_address"
                            onChange={ChangeHandler}
                            value={editVal.depo_address}
                          />
                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <select
                            name="company"
                            onChange={ChangeHandler}
                            className="form-select"
                            value={editVal?.company}
                            // disabled
                          >
                            <option defaultValue="">Select Company Name</option>
                            {companyNameData.map((arr) => {
                              return (
                                <option key={arr?.id} value={arr?.id}>
                                  {arr?.company_name}
                                </option>
                              );
                            })}
                          </select>
                          <span className="fa fa-building icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="submit" >
                          Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
