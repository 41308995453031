import React from 'react';
// import pdffile from "../../assets/images/White LOGO.png"
import pdffile from "../../assets/file/Free_Test_Data_100KB_PDF.pdf"
const FileDownload = ({ downloadUrl }) => {
  console.log(downloadUrl)
  const downloadFile = () => {
    // const content = 'Hello, world!'; // Example content
    // const blob = new Blob([content], { type: 'text/plain' });
    // const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    // link.href =downloadUrl;
    link.href =downloadUrl;
    link.target ="_blank";
    link.download = `file`;
    link.click();

    // Clean up
    // URL.revokeObjectURL(url);
  };

  return (
    <div>
      <button className='btn  btn-primary' onClick={downloadFile}>Download File</button>
      {/* <a class="btn btn-primary" download="barcode.png" href={downloadUrl} >
        Download Now
      </a> */}
    </div>
  );
};

export default FileDownload;
