import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Component/layout/Header'
import Sidebar from './Component/layout/Sidebar'
export default function Layout() {
  return (
    <>
    <Header/>
    <Sidebar/>
    <Outlet />
    </>
  )
}
