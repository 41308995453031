import React, { useState, useEffect } from "react";
import busImg from "../../assets/images/bus-img.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
import { toast, Bounce } from "react-toastify";
export default function DepoAdd({ apicall, setApicall }) {
  let navigate = useNavigate();

  let [formval, setFormval] = useState({ depo_name:"", depo_address:"", company: "" });
  let [companyNameData, setCompanyNameData] = useState([]);
  let ChangeHandler = (e) => {
    // console.log(e.target.name)
    // console.log(e.target.value)
    setFormval((preVal) => ({ ...preVal, [e.target.name]: e.target.value }));
  };

  let loginUser = JSON.parse(localStorage.getItem("user"));

  const companyName = async () => {
    let response = await axios(`${baseUrl}/module/admin/company-viewset`, {
      method: "GET",
      headers: {
        Authorization: "Token " + localStorage.getItem("token"),
      },
    });
    let allData = response.data?.data;
    allData = allData?.filter((arr) => arr.id === loginUser?.company);
    setCompanyNameData(allData);
  };
  useEffect(() => {
    companyName();
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formval);
    if (!formval.depo_name || !formval.company || !formval.depo_address) {
      return alert("all fileds is required");
    }
    // return
    try {
      const response = await axios(`${baseUrl}/module/admin/depo-viewset`, {
        method: "POST",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        data: formval,
      });
      // let a = apicall + 1;
      setApicall(Math.random());
      setFormval({depo_name: "", depo_address: "", company: "" })
      let unique = document.getElementById("unique");
      unique.click();
      setFormval({ depo_name: "", company: "" ,depo_address:"" });
      navigate("/depo-list");
      if(response.data.code === 200){
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }else if(response.data.code === 403){
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
     

      // navigate("/BusList")
    } catch (error) {
      console.error("Error :", error);
    }
  };

  return (
    <>
      <div
        className="modal fade custom-modal addbus-modal"
        id="addbus"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="">
                Add Depot
              </h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="unique"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src={busImg} alt="" />
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Depot Name"
                            name="depo_name"
                            onChange={ChangeHandler}
                            value={formval.depo_name}
                          />
                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Depo Address"
                            name="depo_address"
                            onChange={ChangeHandler}
                            value={formval.depo_address}
                          />
                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <select
                            name="company"
                            onChange={ChangeHandler}
                            className="form-select"
                            value={formval?.company}
                            // disabled
                          >
                            <option defaultValue="">Select Company Name</option>
                            {companyNameData.map((arr) => {
                              return (
                                <option key={arr?.id} value={arr?.id}>
                                  {arr?.company_name}
                                </option>
                              );
                            })}
                          </select>
                          <span className="fa fa-building icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="submit">
                          Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
