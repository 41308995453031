import React, { useState, useEffect } from "react";
import busImg from "../../assets/images/bus-img.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
import { toast, Bounce } from "react-toastify";
export default function BusEdit({editVal, setEditval ,setApicall,apicall}) {
  let navigate = useNavigate();

  // [editVal, setEditval]
  // let [editVal, setEditval] = useState({});
  let [depoData, setDepoData] = useState([]);
  let [companyNameData, setCompanyNameData] = useState([]);
  let loginUser = JSON.parse(localStorage.getItem("user"));

  let ChangeHandler = (e) => {
    setEditval((currVal) => {
      return { ...currVal, [e.target.name]: e.target.value };
    });
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(editVal);
    try {
      const response = await axios(`${baseUrl}/module/admin/bus-viewset/${editVal?.id}`, {
        method: "PUT",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
        data: editVal,
      });
      console.log(response?.data,"edit")
      setApicall(Math.random())
      let unique = document.getElementById("close2");
      unique.click();
      navigate("/bus-list")
      toast.success("Edit Bus Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } catch (error) {
      console.error("Error :", error);
    }
  };


//company and depo list
  useEffect(() => {
    const depo = async () => {
      let response = await axios(`${baseUrl}/module/admin/depo-viewset`, {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      setDepoData(response?.data?.data);
    };
    const companyName = async () => {
      let response = await axios(`${baseUrl}/module/admin/company-viewset`, {
        method: "GET",
        headers: {
          Authorization: "Token " + localStorage.getItem("token"),
        },
      });
      let allData = response?.data?.data;
      allData = allData?.filter((arr) => arr.id === loginUser?.company);
      setCompanyNameData(allData);
    };
    depo();
    companyName();
  }, []);

  return (
    <>
    <div
      className="modal fade custom-modal addbus-modal"
      id="editbus"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="">
                Edit Bus
              </h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close2"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src={busImg} alt="" />
                    <form action="" onSubmit={handleSubmit}>
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <select
                            name="company"
                            onChange={ChangeHandler}
                            className="form-select"
                            defaultValue={editVal?.company_name}
                          >
                            {companyNameData.map((arr) => {
                              return (
                                <option key={arr?.id} value={arr?.id}>
                                 {editVal?.company_name}
                                </option>
                              );
                            })}
                          </select>
                          <span className="fa fa-building icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Bus Number"
                            name="bus_number"
                            onChange={ChangeHandler}
                            value={editVal?.bus_number}
                          />
                          <span className="fa fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <select
                            name="depo"
                            onChange={ChangeHandler}
                            className="form-select"
                            // value={editVal?.depo_name}
                          >
                            <option >Select Depo Name</option>
                            {depoData.map((arr) => {
                              return (
                                <option key={arr?.id} value={arr?.id}  selected={editVal?.depo_name === arr.depo_name && true}>
                                  {arr.depo_name}
                                </option>
                                
                              );
                            })}
                               
                          </select>
                          <span className="fa fa-server icon" />
                        </div>
                       
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="submit">
                          Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
