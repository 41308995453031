import React from "react";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
import barcodeImg from "../../assets/images/barcode.png";
import { Link } from "react-router-dom";
export default function Barcode() {
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar activeItem={"dashboard"} /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-barcode me-2" />
                Barcode
              </h4>
              <a
                href="#"
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addbus"
              >
                <i className="fa fa-plus me-2" />
                Barcode Generate
              </a>
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Library
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                <div className="row justify-content-end">
                  <div className="col-lg-8">
                    <form action="">
                      <div className="row">
                        <div className="col-lg-4">
                          <select name="" id="" className="form-select">
                            <option defaultValue="">Select Company</option>
                            <option value="">Company Name</option>
                          </select>
                        </div>
                        <div className="col-lg-4">
                          <select name="" id="" className="form-select">
                            <option defaultValue="">Select Depo</option>
                            <option value="">Depo Name</option>
                          </select>
                        </div>
                        <div className="col-lg-4">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="checkAll"
                          />
                        </div>
                      </th>
                      <th>Barcode</th>
                      <th>Bus number</th>
                      <th>Company</th>
                      <th>DEPO</th>
                      <th>Depo address</th>
                      <th>Barcode generate Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input checkbox"
                            type="checkbox"
                            defaultValue=""
                            id="check"
                          />
                        </div>
                      </td>
                      <td>
                        <img src={barcodeImg} className="img-50" alt="" />
                      </td>
                      <td>
                        {" "}
                        <span className="text-warning">HR 62 A 7844</span>
                      </td>
                      <td>Haryana Roadways</td>
                      <td>Cathal</td>
                      <td>
                        WRF2+94Q, Kanti Nagar, Sindhi <br /> Camp, Jaipur,
                        Rajasthan 302016
                      </td>
                      <td>29-03-2024</td>
                      <td>
                        <a
                          href="#"
                          className="btn btn-success btn-sm rounded-4"
                        >
                          Download Barcode
                        </a>
                        <a
                          href="#"
                          className="btn btn-outline-danger rounded-circle btn-sm"
                        >
                          <i className="fa fa-trash" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade custom-modal addbus-modal"
        id="addbus"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h5 className="modal-title" id="">
                Barcode Generate
              </h5>
              <button
                type="button"
                className="modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-5 text-center">
                  <div className="addbus-modal-innr">
                    <img src="assets/images/bus-img.png" alt="" />
                    <form action="">
                      <div className="row justify-content-center">
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Company Name"
                          />
                          <span className="fa fa-building icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Depo Name"
                          />
                          <span className="far fa-server icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3 text-start">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Bus Number"
                          />
                          <span className="far fa-bus icon" />
                        </div>
                        <div className="frm-bx mb-4 col-lg-8 mb-3">
                          <button className="thm-btn" type="button">
                            Barcode Generate
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
