import React, { useEffect, useState } from "react";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
import { Link,useNavigate } from "react-router-dom";
import avtar from "../../assets/images/avtar.jpg";
import cardicon01 from "../../assets/images/card-icon-01.svg";
import cardicon02 from "../../assets/images/card-icon-02.svg";
import cardicon03 from "../../assets/images/card-icon-03.svg";
import cardicon04 from "../../assets/images/card-icon-04.svg";
import { baseUrl } from "../../config/baseUrl";
import axios from "axios";
export default function Dashboard() {
  let navigate = useNavigate();
  // let [adminCount, setAdminCount] = useState();
  // let [userCount, setUserCount] = useState();
  let [allCount ,setAllCount]=useState()
  let loginUser = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    try {
   
      (async () => {
        let response = await axios(`${baseUrl}/module/admin/count-viewset`, {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        });
        // console.log(response.data);
        // setAdminCount(response?.data?.admin);
        // setUserCount(response?.data?.user);
        setAllCount(response?.data)
        // console.log(response.data);
        if(response?.data?.code === 401){
          navigate('/login')
          localStorage.clear();
        }
      })();
    } catch (error) {
      console.log("error");
    }
  }, []);
  
  return (
    <>
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="fa fa-home me-2" />
                Dashboard
              </h4>
            </div>
          </div>
          <div className="col-lg-9 col-md-8">
            <div className="cards quick-action">
              <h4 className="dash-head2">Quick Actions</h4>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <Link to="/user-List?model=admin">
                    <div className="quick-action-card">
                      <h5>Admins List</h5>
                      <span className="fa fa-user" />
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-6">
                  <Link to="/user-List">
                    <div className="quick-action-card">
                      <h5>User List</h5>
                      <span className="fa fa-user" />
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-6">
                  <Link to="/bus-list">
                    <div className="quick-action-card">
                      <h5>Bus List</h5>
                      <span className="fa fa-file" />
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-6">
                  <Link to="/report-list">
                    <div className="quick-action-card">
                      <h5>Report List</h5>
                      <span className="fa fa-envelope" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <Link to="/user-List">
                <div className="dash-card prple-clr">
                  <p>Total Users</p>
                  <h5>
                    <span className="counter">{allCount?.user}</span>
                  </h5>
                  <img
                    src={cardicon01}
                    className="card-icon"
                    alt="cardicon01"
                  />
                </div></Link>
              </div>
              <div className="col-lg-3 col-md-6">
              <Link to="/user-List?model=admin">
                <div className="dash-card sky-clr">
                  <p>Total Admin</p>
                  <h5>
                    <span className="counter">{allCount?.admin}</span>
                  </h5>
                  <img
                    src={cardicon02}
                    className="card-icon"
                    alt="cardicon02"
                  />
                </div></Link>
              </div>
              <div className="col-lg-3 col-md-6">
              <Link to="/bus-list">
                <div className="dash-card org-clr">
                  <p>{loginUser?.user_type === "SUPER_ADMIN" ?"Company" :"Bus"}</p>
                  <h5>
                    <span className="counter">{allCount?.company_count}</span>
                  </h5>
                  <img
                    src={cardicon03}
                    className="card-icon"
                    alt="cardicon03"
                  />
                </div></Link>
              </div>
              <div className="col-lg-3 col-md-6">
              <Link to="/depo-list">
                <div className="dash-card pnk-clr">
                  <p>Depo</p>
                  <h5>
                    <span className="counter">{allCount?.depo_count}</span>  
                  </h5>
                  <img
                    src={cardicon04}
                    className="card-icon"
                    alt="cardicon04"
                  />
                </div></Link>
              </div>
              {/* <div className="col-lg-12">
                <div className="cards">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="chart-head mb-3">
                        <h4 className="dash-head2 mb-1">Engagement</h4>
                        <p>Users activity throughout the period</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="chart-fltr text-end">
                        <form action="" className="row">
                          <div className="col-lg-6 text-end">
                            <div className="row gx-2 align-items-center">
                              <div className="col-lg-3 col-md-4">
                                <label htmlFor="" className="col-form-label">
                                  Form:
                                </label>
                              </div>
                              <div className="col-lg-9 col-md-8">
                                <input
                                  type="date"
                                  id=""
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 text-end">
                            <div className="row gx-2 align-items-center">
                              <div className="col-lg-3 col-md-4">
                                <label htmlFor="" className="col-form-label">
                                  To:
                                </label>
                              </div>
                              <div className="col-lg-9 col-md-8">
                                <input
                                  type="date"
                                  id=""
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="chart-container" />
                </div>
              </div> */}
              <div className="col-lg-12">
                <div className="cards know-abt">
                  <h4 className="dash-head2">
                    Everything you need to know about AFC Tracker
                  </h4>
                  <div className="know-abt-innr">
                    <Link
                      to="#"
                      className="watch-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#video-popup"
                    >
                      <i className="fa fa-play" />
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-12">
                <div className="cards">
                  <h4 className="dash-head2 d-flex justify-content-between align-items-center">
                    Daily Activity
                    <Link to="#" className="thm-btn brdr-btn">
                      View History
                    </Link>
                  </h4>
                  <ul className="activity-list">
                    <li>
                      <div className="line" />
                      <div className="activity-icon">
                        <span className="date">Tue 26/3</span>
                      </div>
                    </li>
                    <li>
                      <div className="line" />
                      <div className="activity-icon">
                        <span className="activity-icon-innr">
                          <i className="fa fa-clock-o" />
                        </span>
                      </div>
                      <div className="activity-img-box">
                        <img src={avtar} alt="avtar" />
                        <img src={avtar} alt="avtar" />
                      </div>
                      <div>
                        <p>
                          sunil sharma&nbsp;published ajay sharma's shift&nbsp;-
                          Manager2 on 27/03 from 09:00 - 17:00 in the&nbsp;Job
                          Scheduler&nbsp;scheduleron 28/3 at 11:10
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="line" />
                      <div className="activity-icon">
                        <span className="activity-icon-innr">
                          <i className="fa fa-clock-o" />
                        </span>
                      </div>
                      <div className="activity-img-box">
                        <img src={avtar} alt="avtar" />
                        <img src={avtar} alt="avtar" />
                      </div>
                      <div>
                        <p>
                          sunil sharma&nbsp;published ajay sharma's shift&nbsp;-
                          Manager2 on 27/03 from 09:00 - 17:00 in the&nbsp;Job
                          Scheduler&nbsp;scheduleron 28/3 at 11:10
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="line" />
                      <div className="activity-icon">
                        <span className="activity-icon-innr">
                          <i className="fa fa-clock-o" />
                        </span>
                      </div>
                      <div className="activity-icon">
                        <span className="activity-icon-innr">
                          <i className="fa fa-cog fa-spin" />
                        </span>
                      </div>
                      <div>
                        <p>
                          sunil sharma&nbsp;published ajay sharma's shift&nbsp;-
                          Manager2 on 27/03 from 09:00 - 17:00 in the&nbsp;Job
                          Scheduler&nbsp;scheduleron 28/3 at 11:10
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-4">
            <div className="cards clear-card">
              <span className="fa fa-check" />
              <h4>You're in the clear</h4>
              <p>
                New time off requests, shift requests, and requests to join the
                app will show up right here
              </p>
            </div>
            <div className="cards clear-card dark-clr">
              <span className="fa fa-check" />
              <h4 className="text-white">Users didn't log in to the app yet</h4>
              <Link to="#" className="thm-btn">
                Select users to invite
              </Link>
            </div>
            <div className="cards clear-card light-clr">
              <span className="fa fa-check" />
              <h4>You’re all caught up</h4>
              <p>No new alerts to display at this time</p>
              <Link to="/privacy-policy" className="thm-btn mt-3">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </section>
      {/* video model */}
        <div
          className="modal fade video"
          id="video-popup"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <iframe
                  width="100%"
                  height={500}
                  src="https://www.youtube.com/embed/yAoLSRbwxL8?si=jQNYMNhkHAWel9v2"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen=""
                />
              </div>
            </div>
          </div>
        </div>

    </>
  );
}
