import React, { useEffect, useState } from "react";
// import Header from "../layout/Header";
// import Sidebar from "../layout/Sidebar";
import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { ToastMessgae } from "../utils/toast";
// import DepoAdd from "./DepoAdd";
import DeleteToast from "../Users/DeleteToast";
import { Link } from "react-router-dom";
// import DepoEdit from "./DepoEdit";
import Pagination from "../Pagination/Pagination";
export default function DepoListForChangeScheduling() {
  let [depoData, setDepoData] = useState([]);
  let [apicall, setApicall] = useState(1);
  let [deleteid, setDeleteid] = useState();
  let [editVal, setEditval] = useState({});
  let [pageNumber, setPageNumber] = useState(1);
  let [pageLimit, setPageLimit] = useState(1);
  let loginUser = JSON.parse(localStorage.getItem("user"));
  // let [depoData, setDepoData] = useState([]);
  let [companyNameData, setCompanyNameData] = useState([]);
  let [searchData, setSearchData] = useState({
    company: "",
  });
  let color  =["primary","secondary","success","warning","info","light","danger"]
  let compare=["","","","","","",""]
  const dayOrder = {
    "monday": 0,
    "tuesday": 1,
    "wednesday": 2,
    "thursday": 3,
    "friday": 4,
    "saturday": 5,
    "sunday": 6
};
let day = ["monday","tuesday","wednesday","thursday", "friday","saturday","sunday"]
//  color.sort((arr)=>console.log(arr))
  //  all depot data
  useEffect(() => {
    const depo = async () => {
      let response = await axios(
        `${baseUrl}/module/admin/depo-viewset?page=${pageNumber}&company=${searchData.company}`,
        {
          method: "GET",
          headers: {
            Authorization: "Token " + localStorage.getItem("token"),
          },
        }
      );
      setPageLimit(response?.data?.extra?.total);
      setDepoData(response?.data?.data || []);
    };
    depo();
  }, [apicall, pageNumber]);

  let filterHandler = async (e) => {
    setSearchData((curr) => {
      return { ...curr, [e.target.name]: e.target.value };
    });
  };



  



  // let handleSearch = async (e) => {
  //   // url: 'https://admin.cleaningcrm.fluttertrends.com/module/admin/search-scheduler',
  //   console.log(searchData);
  //   e.preventDefault();
  //   try {
  //     // https://admin.cleaningcrm.fluttertrends.com/module/admin/search-scheduler
  //     //https://admin.cleaningcrm.fluttertrends.com/module/admin/depo_update/ID
  //     let response = await axios(
  //       `${baseUrl}/module/admin/depo_update/${id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: "Token " + localStorage.getItem("token"),
  //         },
  //         data: searchData,
  //       }
  //     );
  //     console.log(response.data);
  //     if (response.data.code === 200) {
  //       setDepoData(response.data.data || []);
  //       setPageLimit(response?.data?.extra.total);
  //       // setExtra(response?.data?.extra)
  //       // return
  //     } else if (response.data.code === 404) {
  //       ToastMessgae(response.data);
  //     } else if (response.data.code === 400) {
  //       ToastMessgae(response.data);
  //     }
  //   } catch (error) {
  //     alert("some error from Backend");
  //   }
  // };

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar activeItem={"DepoManagement"} /> */}
      <section className="main-sec">
        <div className="row">
          <div className="col-lg-12">
            <div className="dashboard-title">
              <h4 className="dash-head">
                <i className="far fa-chart-bar me-2" />
                Depot List
              </h4>
              {/* <Link
                to="#"
                className="blu-btn"
                data-bs-toggle="modal"
                data-bs-target="#addbus"
              >
                <i className="fa fa-plus me-2" />
                Add
              </Link> */}
            </div>
            <div className="custom-bredcump">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Change-scheduling
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="cards bus-list">
              <div className="bus-filter">
                {/* <div className="row justify-content-end">
                  <div className="col-lg-8">
                    <form action="">
                      <div className="row justify-content-end">
                        {loginUser.user_type === "SUPER_ADMIN" && (
                          <>
                            <div className="col-lg-4">
                              <select
                                name="company"
                                onChange={filterHandler}
                                className="form-select"
                              >
                                <option defaultValue="">Select Company</option>
                                {companyNameData?.map((arr) => (
                                  <option value={arr?.id} key={arr?.id}>
                                    {arr?.company_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-2">
                              <button
                                className="thm-btn w-100"
                                onClick={handleSearch}
                              >
                                Search
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </div>
                </div> */}
              </div>
              <div className="table table-responsive custom-table">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th>
                        {/* <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="checkAll"
                          />
                        </div> */}
                        Sr. no.
                      </th>
                      <th>Depot Name</th>
                      <th>Depot address</th>
                      <th>Company Name</th>
                     
                      {(loginUser.user_type === "ADMIN_MANAGER" ||  loginUser?.user_type === "CLEANING_MANAGER") && <th>change schedule</th>}
                      <th >days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depoData?.map((arr, i) => {
                      return (
                        <tr key={arr?.id}>
                          <td>
                            {/* <div className="form-check">
                              <input
                                className="form-check-input checkbox"
                                type="checkbox"
                                defaultValue=""
                                id="check"
                              />
                            </div> */}
                            {++i + 10 * (pageNumber - 1)}
                          </td>
                          <td>
                            <span>{arr?.depo_name}</span>
                          </td>
                          <td>
                            <span>{arr?.depo_address}</span>
                          </td>
                          <td>
                            <span>{arr?.company?.company_name}</span>
                          </td>
                          

                         {(loginUser.user_type === "ADMIN_MANAGER" ||  loginUser?.user_type === "CLEANING_MANAGER") && <td className=""> 
                          <Link
                              className="btn btn-success"
                              to={`/change-scheduling/${arr.id}`}
                            >
                              <i className="fa fa-calendar" />
                            </Link>
                          </td>}
                          <td >
                            {day.map((arr1,i)=>{ 
                              return arr?.days?.map((arr)=>(
                                arr1 == arr && <button key={arr} className={`btn btn-sm btn-${color[i]} me-2`} > {arr.slice(0,3)} </button>
                             )) 
                            })}
                          </td>
                          
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* return arr?.days?.map((arr)=>(
                  arr1 == arr && <button className={`btn btn-sm btn-${color[i]} me-2`} > {arr.slice(0,3)} </button>
               )) */}
                <Pagination
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageLimit={pageLimit}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <DepoAdd apicall={apicall} setApicall={setApicall} />
      <DeleteToast deleteHandle={deleteHandle} id={deleteid} />;
      <DepoEdit
        editVal={editVal}
        setEditval={setEditval}
        apicall={apicall}
        setApicall={setApicall}
      /> */}
    </>
  );
}
