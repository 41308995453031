import React, { useState } from "react";
import logo from "../../assets/images/main-logo.png";
import { Link, NavLink } from "react-router-dom";
export default function Sidebar({ activeItem }) {
  let loginUser = JSON.parse(localStorage.getItem("user"));
  let [loginUserType, setLoginUserType] = useState(loginUser?.user_type);
  // const [activeItem, setActiveItem] = useState();
  let addBodyClass = () => {
    let w = window.innerWidth;
    if (w < 1200) {
      document?.body?.classList?.toggle("resize-menu");
    }
  };
  let onClickaddBodyClass = () => {
    let w = window.innerWidth;
    if (w < 1200) {
      document?.body?.classList?.toggle("resize-menu");
      return;
    }
    document.body.classList.add("resize-menu");
  };

  return (
    <>
      <div className="dash-menu">
        <div className="dashbord-logo">
          <Link to="" className="navbar-brand">
            <img src={logo} alt="Logo" />
          </Link>
          <Link
            to=""
            className="toggle cross-icon"
            onClick={onClickaddBodyClass}
          >
            <i className="fa fa-chevron-left" />
          </Link>
        </div>
        <div className="dash-menu-bar">
          <nav className="nav-menu navbar navbar-expand-lg" id="navbar">
            <ul>
              <li className="nav-item" onClick={addBodyClass}>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <span className="fa fa-th-large me-2 icon" />
                  dashboard
                </NavLink>
              </li>
              {/* <li className="nav-item" onClick={addBodyClass}>
                <Link
                  to=""
                  className={`nav-link ${activeItem === "Activity" ? "active" : ""
                    }`}
                >
                  <span className="fa fa-bar-chart me-2 icon" />
                  Activity
                </Link>
              </li> */}
              <li className="nav-item" onClick={addBodyClass} >
                <NavLink
                  to="/user-List"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <span className="fa fa-users me-2 icon" /> Users
                </NavLink>
              </li>
              <li className="nav-item " onClick={addBodyClass} >
              <NavLink
                  to="/bus-list"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }>
                  <span className="fa fa-bus me-2 icon " />
                  Bus Management
                </NavLink>
              </li>
              <li className="nav-item" onClick={addBodyClass}>
                <NavLink
                  to="/report-list"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <span className="fa fa-flag me-2 icon" />
                  Report Management 
                </NavLink>
              </li>

              <li className="nav-item" onClick={addBodyClass}>
                <NavLink
                  to="/depo-list"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                >
                  <span className="fa fa-bus me-2 icon" />
                  Depot Management
                </NavLink>
              </li>
              {loginUserType === "SUPER_ADMIN" && (
                <>
                  <li className="nav-item" onClick={addBodyClass}>
                    <NavLink
                      to="/company-list"
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      <span className="fa fa-bus me-2 icon" />
                      Company Management
                    </NavLink>
                  </li>
                </>
              )}
              {/* <li className="nav-item " onClick={addBodyClass} >
              <NavLink
                  to="/job-schedule"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }>
                  <span className="fa fa-bus me-2 icon " />
                 Job Schedule
                </NavLink>
              </li> */}
              <li className="nav-item " onClick={addBodyClass} >
              <NavLink
                  to="/job-scheduled"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }>
                  <span className="fa fa-bus me-2 icon " />
                 Job Schedule
                </NavLink>
              </li>
            

              {/* <li className="nav-item">
                <Link to="" className="nav-link">
                  <span className="fa fa-users me-2 icon" />
                  Smart Groups
                </Link>
              </li>
              <li className="nav-item nav-title">
                <h6>Operations</h6>
              </li>
              <li className="nav-item">
                <Link to="" className="nav-link">
                  <span className="fa fa-clock-o me-2 icon" />
                  Time clock
                </Link>
              </li>
              <li className="nav-item">
                <Link to="" className="nav-link">
                  <span className="fa fa-file me-2 icon" />
                  Forms
                </Link>
              </li> 
              <li className="nav-item" onClick={addBodyClass}>
                <Link
                  to="/job-schedule"
                  className={`nav-link ${activeItem === "Rostering" ? "active" : ""
                    }`}
                >
                  <span className="fa fa-calendar me-2 icon" />
                  job scheduling
                </Link>
              </li>
               <li className="nav-item nav-title">
                <h6>Communications</h6>
              </li>
              <li className="nav-item">
                <Link to="" className="nav-link">
                  <span className="fa fa-address-book me-2 icon" />
                  Directory
                </Link>
              </li>
              <li className="nav-item nav-title">
                <h6>HR &amp; Skills</h6>
              </li>
              <li className="nav-item">
                <Link to="" className="nav-link">
                  <span className="fa fa-power-off me-2 icon" />
                  Time off
                </Link>
              </li>
              <li className="nav-item">
                <Link to="" className="nav-link">
                  <span className="fa fa-book me-2 icon" />
                  Courses
                </Link>
              </li>
              <li className="nav-item">
                <Link to="" className="nav-link">
                  <span className="fa fa-copy me-2 icon" />
                  Documents
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
