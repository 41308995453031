import { toast, Bounce } from "react-toastify";
export function ToastMessgae(result) {
  console.log(result, "by toast");
  if (result?.code === 200 || result?.code === 201) {
    toast.success(result?.message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    // navigator("/user-list")
  } else {
    toast.error(result?.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }
}
