import React from "react";
import { Link } from "react-router-dom";

export default function Pagination({ pageNumber, setPageNumber, pageLimit ,paginationDetails}) {
  
  return (
    <>
      <div class="row justify-content-between">
        <div class="col-lg-6">
          <p>Showing { (paginationDetails?.total == (pageNumber)) ? ( paginationDetails?.count ) : ( paginationDetails?.count ==0 ? 0: paginationDetails?.page_size * pageNumber)} to {paginationDetails?.count} of {paginationDetails?.count} entries</p>
        </div>
        <div class="col-lg-6">
          <div class="custom-pagination">
            <nav aria-label="Page navigation example">
              <ul className="pagination ">
                {pageNumber > 1 && (
                  <>
                    <li
                      className="page-item"
                      onClick={() => setPageNumber((prev) => prev - 1)}
                    >
                      <Link className="page-link" to="#">
                        Previous
                      </Link>
                    </li>
                    <li
                      className="page-item "
                      onClick={() => setPageNumber((prev) => prev - 1)}
                    >
                      <Link className="page-link" to="#">
                        {pageNumber - 1}
                      </Link>
                    </li>
                  </>
                )}

                <li className={`page-item active `}>
                  <Link className="page-link" to="#">
                    {pageNumber}
                  </Link>
                </li>

                {pageNumber < pageLimit && (
                  <>
                     
                    <li
                      className="page-item "
                      onClick={() => setPageNumber((prev) => prev + 1)}
                    >
                      <Link className="page-link" to="#">
                        {pageNumber + 1}
                      </Link>
                    </li>
                    {/* <li
              className="page-item"
              onClick={() => setPageNumber((prev) => prev + 2)}
            >
              <Link className="page-link" to="#">
                {pageNumber + 2}
              </Link>
            </li> */}
                    <li
                      className="page-item"
                      onClick={() => setPageNumber((prev) => prev + 1)}
                    >
                      <Link className="page-link" to="#">
                        Next
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
