import React from 'react'

export default function PrivacyPolicy() {
  return (
    <section className="content-sec">
    <div className="container">
      <h2 className="text-center">Privacy Policy AFC Tracker</h2>
      <p>
        At AFC Tracker , accessible from app.advancedfacilitycare.co.nz  , one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by Youth
        Education and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <p>
        This Privacy Policy applies only to our online activities and is valid for
        visitors to our website with regards to the information that they shared
        and/or collect in Youth Education. This policy is not applicable to any
        information collected offline or via channels other than this website.
      </p>
      <h4>Consent</h4>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
      <h4>Information we collect</h4>
      <p>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point we
        ask you to provide your personal information.
      </p>
      <p>
        If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information you
        may choose to provide.
      </p>
      <p>
        When you register for an Account, we may ask for your contact information,
        including items such as name, company name, address, email address, and
        telephone number.
      </p>
      <h4>How we use your information</h4>
      <p>We use the information we collect in various ways, including to:</p>
      <h4>Delete Account</h4>
      <p>
        For delete your account you can sent email{" "}
        <a href="mailto:appservernz@gmail.com">appservernz@gmail</a>.com with your
        details . Call Us : <a href="tel:+64 277711110">+64 27 771 1110</a>
      </p>
    </div>
  </section>
  
  )
}
